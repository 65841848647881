<template>
  <keep-alive>
    <el-pagination @size-change="sizeChange" @current-change="currentChange"
                   :current-page="page" :page-size="size" :page-sizes="pageSizes"
                   layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </keep-alive>
</template>
<script>

import qs from "qs";
import _ from "lodash";
import init from "@/utils/init";

export default {
  name: 'Page',
  props: {
    AllTableList: {type: Array,},
    tableList: {type: Array},
    url: {type: String},
    getListFunction: {type: Function},
    type: {type: String},
    flag: {type:Boolean,default:false},
    sizeDe: {type:Number,default:100}
  },
  data() {
    return {
      page: 1, //第几页
      // size: init.page[0]<20 ? 20:init.page[2], //一页多少条
      total: 0, //总条目数
      pageSizes: init.page,
      size: this.sizeDe,
      // 搜索
      params: {}
    };
  },
  watch: {
    AllTableList: function () {
      this.getWebList()
    },
    total: function (newValue) {
      // 除取整 得到最大分页
      let maxPage = Math.ceil(newValue/this.size)
      if(maxPage < this.page && maxPage > 0){
        this.currentChange(maxPage) // 刷新页面
      }
    },
    flag: function (){
      this.getTableList()
    }
  },

  methods: {
    //获取表格数据，自行分页(slice)
    getTableList() {
      if(this.AllTableList && this.AllTableList.length > 0) {
        this.getWebList()
      } else {
        // 如果没有传入AllTableList 就要传入URL 实现后端分页
        this.getListFunction?.()
      }
    },

    // 前端分页
    getWebList() {
      //AllTableList为前端分页 传入 所有List
      let tableList = this.AllTableList.slice(
        (this.page - 1) * this.size,
        this.page * this.size
      );
      this.$emit('update:tableList', tableList)
      this.total = this.AllTableList.length
    },

    // 后端分页 获取每页后台数据
    async getServerList(url = this.url, params = this.params) {
      let filterParams = _.clone(params || {})
      this.params = filterParams
      let res = null
      if( this.type === "new" ) {
        res = await this.$curl.post( url, {
          params:{ pageIndex: this.page, pageNumber: this.size },
          ...filterParams
        })
      } else if(this.type === "old") {
        res = await this.$curl.post(url + '?pageNum='+this.page+'&pageSize='+this.size, filterParams)
      } else {
        const param = {
          pageNum : this.page, pageSize: this.size,
          ...filterParams
        }
        res = await this.$curl.get(url + '?' +  qs.stringify(param) )
      }
      this.total = res.total
      return res
    },

    // page改变时的回调函数，参数为当前页码
    currentChange(val) {
      console.log(this.size,'this.size')
      this.page = val;
      this.getTableList();
    },

    // size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      this.size = val;
      this.page = 1;
      this.getTableList();
    },

  },

};
</script>
